import React, {FC, useEffect, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {TFunction} from 'i18next'

import UserInfoField, {UserInfoFieldProps} from 'src/products/MCU/Profile/UserInfoField'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {BusyHook} from 'src/products/MCU/EmailConfirmation/EmailConfirmation.utils'
import {
  formatAddressMultiline,
  openEditEmail,
  openEditPassword,
  verifyEmail,
  onEditAddress,
} from 'src/products/MCU/Profile/Profile.utils'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {getLoginMethodUserProperty} from 'src/api/lib/UserProperties/LoginMethodUserPropertyUtils'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {UserProfileInfoDocument} from 'src/products/MCU/Profile/Profile.gqls'
import Log from 'src/lib/loggingUtil'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {useCanUserUpdateAddress} from 'src/products/general/AddressUpdates/useCanUserUpdateAddress'
import {AddressUpdateErrorModal} from 'src/products/general/AddressUpdates/AddressUpdateErrorModal'

type Props = StackScreenProps<MainStackParamList, 'Profile'>
type Navigation = Props['navigation']

const passwordDisplay = '\u2022'.repeat(10)

type Fn = () => void
export type ProfileScreenUserDataType =
  | {
      __typename: 'UserProfile'
      name?: {
        __typename: 'Name'
        firstName: string
        lastName?: string | null
        middleName?: string | null
      } | null
      phone?: {__typename: 'Phone'; verified: boolean; number?: string | null} | null
      email?: {__typename: 'Email'; address?: string | null; verified: boolean} | null
      home?: {
        __typename: 'Location'
        address?: {
          __typename: 'Address'
          street1?: string | null
          street2?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
        } | null
      } | null
    }
  | null
  | undefined

const info = (
  user: ProfileScreenUserDataType,
  navigation: Navigation,
  showEditPassword: boolean,
  t: TFunction,
  emailVerified: boolean,
  busy: boolean,
  setBusy: BusyHook,
  canUserUpdateAddress: boolean,
  isAddressUpdateEnabled: boolean,
  showAddressModal: () => void,
): UserInfoFieldProps[] => {
  const editPasswordField = showEditPassword
    ? [
        {
          label: t('Password'),
          value: passwordDisplay,
          isEditable: true,
          onPress: (): void => {
            openEditPassword(navigation)
          },
        },
      ]
    : []

  const verifyEmailAction = emailVerified
    ? undefined
    : {
        text: t('VerifyEmail'),
        onPress: (): Promise<void> => verifyEmail(navigation, setBusy),
        disabled: busy,
        testID: 'VerifyEmail',
      }

  return [
    {label: t('Name'), value: user?.name?.firstName ?? ''},
    {label: t('PhoneNumber'), value: user?.phone?.number ?? ''},
    {
      label: t('Email'),
      value: user?.email?.address ?? '',
      isEditable: true,
      onPress: () => openEditEmail(navigation),
      action: verifyEmailAction,
    },
    ...editPasswordField,
    {
      label: t('Address'),
      value: formatAddressMultiline(user?.home?.address),
      isEditable: canUserUpdateAddress,
      showInfoIcon: isAddressUpdateEnabled,
      onPress: () =>
        isAddressUpdateEnabled && !canUserUpdateAddress
          ? showAddressModal()
          : onEditAddress(navigation, t('AddressEditTitle')),
    },
  ]
}

const Profile: FC<Props> = ({navigation}) => {
  const {data: userProfileData, loading: isLoadingProfileData} = useCassandraQuery(
    UserProfileInfoDocument,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        Log.error(`${error.message} - Profile Screen - UserProfileInfoDocument query`)
      },
    },
  )
  const {
    isAddressUpdateEnabled,
    canUserUpdateAddress,
    isLoading: isLoadingCanUserUpdateAddress,
  } = useCanUserUpdateAddress()
  const userInfo = userProfileData?.me?.profile

  const isEmailVerified = userProfileData?.me?.profile?.email?.verified ?? false
  const [showEditPassword, setShowEditPassword] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {t} = useTranslation('Profile')

  useEffect(() => {
    const getLoginMethod = async (): Promise<void> => {
      const loginMethod = await getLoginMethodUserProperty()
      // If a user uses oauth we don't want to let them edit password as they likely don't have one set yet
      setShowEditPassword(loginMethod !== 'oauth')
    }
    void getLoginMethod()
  }, [])

  if (isLoadingProfileData || isLoadingCanUserUpdateAddress) {
    return <Spinner />
  }
  const hideAddressModal: Fn = () => setShowModal(false)
  const showAddressModal: Fn = () => setShowModal(true)

  const infoList = info(
    userInfo,
    navigation,
    showEditPassword,
    t,
    isEmailVerified,
    isBusy,
    setIsBusy,
    canUserUpdateAddress,
    isAddressUpdateEnabled,
    showAddressModal,
  ).map((item) => (
    <Box marginTop={'large'} key={item.label}>
      <UserInfoField
        label={item.label}
        value={item.value}
        isEditable={item.isEditable}
        onPress={item.onPress}
        valueExtra={item.valueExtra}
        showInfoIcon={item.showInfoIcon}
        action={item.action}
      />
    </Box>
  ))

  return (
    <Page
      title={t('Profile')}
      variant="generic"
      testID="ProfilePage"
      contentVerticalAlignment="flex-start"
      smallTopGap={true}
    >
      {infoList}
      <AddressUpdateErrorModal
        hideModal={hideAddressModal}
        showModal={showModal}
        navigation={navigation}
        canUpdateAddress={canUserUpdateAddress}
      />
    </Page>
  )
}

export {Profile}
