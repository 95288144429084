import AccountManagement from 'src/lib/localization/en/AccountManagement.json'
import AccountsWithPossible from 'src/lib/localization/en/AccountsWithPossible.json'
import AccountOwnership from 'src/lib/localization/en/AccountOwnership.json'
import AccountRecovery from 'src/lib/localization/en/AccountRecovery.json'
import AddBankLink from 'src/lib/localization/en/AddBankLink.json'
import AddDebitCard from 'src/lib/localization/en/AddDebitCard.json'
import AdhocPayment from 'src/lib/localization/en/AdhocPayment.json'
import AdhocPaymentAmountEntry from 'src/lib/localization/en/AdhocPaymentAmountEntry.json'
import Advance from 'src/lib/localization/en/Advance.json'
import ApplicationProcessing from 'src/lib/localization/en/ApplicationProcessing.json'
import ApplicationSteps from 'src/lib/localization/en/ApplicationSteps.json'
import ApplyInOneClick from 'src/lib/localization/en/ApplyInOneClick.json'
import ApplyInFewSteps from 'src/lib/localization/en/ApplyInFewSteps.json'
import AppReviewManager from 'src/lib/localization/en/AppReviewManager.json'
import AttemptsList from 'src/lib/localization/en/AttemptsList.json'
import Bank from 'src/lib/localization/en/Bank.json'
import BankAggregatorAccountSelection from 'src/lib/localization/en/BankAggregatorAccountSelection.json'
import BankAggregatorAccountSelectionForPrimary from 'src/lib/localization/en/BankAggregatorAccountSelectionForPrimary.json'
import BankFees from 'src/lib/localization/en/BankFees.json'
import BankNumbersForm from 'src/lib/localization/en/BankNumbersForm.json'
import BinaryVersionUpdate from 'src/lib/localization/en/BinaryVersionUpdate.json'
import CameraPage from 'src/lib/localization/en/CameraPage.json'
import CancelLoan from 'src/lib/localization/en/CancelLoan.json'
import CardAcceptAgreement from 'src/lib/localization/en/CardAcceptAgreement.json'
import CardActivatedTemplate from 'src/lib/localization/en/CardActivatedTemplate.json'
import CardActivatePhysicalCard from 'src/lib/localization/en/CardActivatePhysicalCard.json'
import CardActivatingPhysicalCard from 'src/lib/localization/en/CardActivatingPhysicalCard.json'
import CardActivation from 'src/lib/localization/en/CardActivation.json'
import CardAdhocPayment from 'src/lib/localization/en/CardAdhocPayment.json'
import CardAdhocPaymentAmountEntry from 'src/lib/localization/en/CardAdhocPaymentAmountEntry.json'
import CardAdhocPaymentSuccess from 'src/lib/localization/en/CardAdhocPaymentSuccess.json'
import CardAgreementViewer from 'src/lib/localization/en/CardAgreementViewer.json'
import CardAgreementBureau from 'src/lib/localization/en/CardAgreementBureau.json'
import CardAlert from 'src/lib/localization/en/CardAlert.json'
import CardApplicationFinancesTemplate from 'src/lib/localization/en/CardApplicationFinancesTemplate.json'
import CardApplicationRejected from 'src/lib/localization/en/CardApplicationRejected.json'
import CardApprovedTemplate from 'src/lib/localization/en/CardApprovedTemplate.json'
import CardAutopayOverviewTemplate from 'src/lib/localization/en/CardAutopayOverviewTemplate.json'
import CardDashboard from 'src/lib/localization/en/CardDashboard.json'
import CardDashboardEntryPointTiles from 'src/lib/localization/en/CardDashboardEntryPointTiles.json'
import CardDashboardHeader from 'src/lib/localization/en/CardDashboardHeader.json'
import CardDashboardPayment from 'src/lib/localization/en/CardDashboardPayment.json'
import CardDashboardPaymentFooter from 'src/lib/localization/en/CardDashboardPaymentFooter.json'
import CardDashboardPayOverTime from 'src/lib/localization/en/CardDashboardPayOverTime.json'
import CardDetails from 'src/lib/localization/en/CardDetails.json'
import CardHowDelinquencyAffectMe from 'src/lib/localization/en/CardHowDelinquencyAffectMe.json'
import CardHowPaymentsWork from 'src/lib/localization/en/CardHowPaymentsWork.json'
import CardHowPayOverTimeWorks from 'src/lib/localization/en/CardHowPayOverTimeWorks.json'
import CardMakePaymentPOT from 'src/lib/localization/en/CardMakePaymentPOT.json'
import CardManualPaymentSchedule from 'src/lib/localization/en/CardManualPaymentSchedule.json'
import CardManualPayOverviewTemplate from 'src/lib/localization/en/CardManualPayOverviewTemplate.json'
import CardPaymentAgreement from 'src/lib/localization/en/CardPaymentAgreement.json'
import CardPaymentScheduleTemplate from 'src/lib/localization/en/CardPaymentScheduleTemplate.json'
import CardPayOverTimeDetails from 'src/lib/localization/en/CardPayOverTimeDetails.json'
import CardPayOverTimeScheduleTemplate from 'src/lib/localization/en/CardPayOverTimeScheduleTemplate.json'
import CardPhysicalCardActivated from 'src/lib/localization/en/CardPhysicalCardActivated.json'
import CardPhysicalCardActivationFailed from 'src/lib/localization/en/CardPhysicalCardActivationFailed.json'
import CardPOTOverdue from 'src/lib/localization/en/CardPOTOverdue.json'
import CardProductIntroduction from 'src/lib/localization/en/CardProductIntroduction.json'
import CardRescheduleUpcomingPayment from 'src/lib/localization/en/CardRescheduleUpcomingPayment.json'
import CardReviewAutopay from 'src/lib/localization/en/CardReviewAutopay.json'
import CardSetupAutopayments from 'src/lib/localization/en/CardSetupAutopayments.json'
import CardsHistory from 'src/lib/localization/en/CardsHistory.json'
import CardSignedUpAutopay from 'src/lib/localization/en/CardSignedUpAutopay.json'
import CardSubmitApplication from 'src/lib/localization/en/CardSubmitApplication.json'
import CardTransaction from 'src/lib/localization/en/CardTransaction.json'
import CardUnlockedModal from 'src/lib/localization/en/CardUnlockedModal.json'
import CardVerifyBankDetails from 'src/lib/localization/en/CardVerifyBankDetails.json'
import CardViewPaymentMethods from 'src/lib/localization/en/CardViewPaymentMethods.json'
import CashAcceptAgreements from 'src/lib/localization/en/CashAcceptAgreements.json'
import CashAmountSelection from 'src/lib/localization/en/CashAmountSelection.json'
import CashHowPaymentsWork from 'src/lib/localization/en/CashHowPaymentsWork.json'
import CashOnTheWay from 'src/lib/localization/en/CashOnTheWay.json'
import CashProductOverview from 'src/lib/localization/en/CashProductOverview.json'
import CashSelectDisbursementMethod from 'src/lib/localization/en/CashSelectDisbursementMethod.json'
import Common from 'src/lib/localization/en/Common.json'
import ConfirmBankAccount from 'src/lib/localization/en/ConfirmBankAccount.json'
import ConfirmDebitCard from 'src/lib/localization/en/ConfirmDebitCard.json'
import ConfirmDeclineCounterOffer from 'src/lib/localization/en/ConfirmDeclineCounterOffer.json'
import ConfirmDetailsAcceptLoan from 'src/lib/localization/en/ConfirmDetailsAcceptLoan.json'
import ContactUs from 'src/lib/localization/en/ContactUs.json'
import DashboardLoan from 'src/lib/localization/en/DashboardLoan.json'
import DashboardLoanAlertTile from 'src/lib/localization/en/DashboardLoanAlertTile.json'
import DashboardLoanApprovedTile from 'src/lib/localization/en/DashboardLoanApprovedTile.json'
import DashboardLoanCalendarTile from 'src/lib/localization/en/DashboardLoanCalendarTile.json'
import DashboardLoanCancelledTile from 'src/lib/localization/en/DashboardLoanCancelledTile.json'
import DashboardLoanChargedOffSettledTile from 'src/lib/localization/en/DashboardLoanChargedOffSettledTile.json'
import DashboardLoanDecisioningDelayedTile from 'src/lib/localization/en/DashboardLoanDecisioningDelayedTile.json'
import DashboardLoanDecisioningTile from 'src/lib/localization/en/DashboardLoanDecisioningTile.json'
import DashboardLoanEligibleToApplyTile from 'src/lib/localization/en/DashboardLoanEligibleToApplyTile.json'
import DashboardLoanExpiredTile from 'src/lib/localization/en/DashboardLoanExpiredTile.json'
import DashboardLoanHistoryTile from 'src/lib/localization/en/DashboardLoanHistoryTile.json'
import DashboardLoanPaidOffTile from 'src/lib/localization/en/DashboardLoanPaidOffTile.json'
import DashboardLoanProgressTile from 'src/lib/localization/en/DashboardLoanProgressTile.json'
import DashboardLoanRejectedTile from 'src/lib/localization/en/DashboardLoanRejectedTile.json'
import DashboardLoanUnmetStateRequirementsTile from 'src/lib/localization/en/DashboardLoanUnmetStateRequirementsTile.json'
import DashboardWhyChargedOffModal from 'src/lib/localization/en/DashboardWhyChargedOffModal.json'
import DashboardWhyDefaultModal from 'src/lib/localization/en/DashboardWhyDefaultModal.json'
import DateBubble from 'src/lib/localization/en/DateBubble.json'
import DisbursementMethodSelection from 'src/lib/localization/en/DisbursementMethodSelection.json'
import Documents from 'src/lib/localization/en/Documents.json'
import DocumentsHistory from 'src/lib/localization/en/DocumentsHistory.json'
import EmailConfirmation from 'src/lib/localization/en/EmailConfirmation.json'
import EmailVerification from 'src/lib/localization/en/EmailVerification.json'
import EmailPasswordForm from 'src/lib/localization/en/EmailPasswordForm.json'
import EmailPasswordManagement from 'src/lib/localization/en/EmailPasswordManagement.json'
import ErrorAlertLightbox from 'src/lib/localization/en/ErrorAlertLightbox.json'
import Errors from 'src/lib/localization/en/Errors.json'
import ErrorNotice from 'src/lib/localization/en/ErrorNotice.json'
import GoogleLogin from 'src/lib/localization/en/GoogleLogin.json'
import HowAutoPayWorksBottomSheet from 'src/lib/localization/en/HowAutoPayWorksBottomSheet.json'
import HowDoesAutopayWork from 'src/lib/localization/en/HowDoesAutopayWork.json'
import HowPaymentsWork from 'src/lib/localization/en/HowPaymentsWork.json'
import IDScan from 'src/lib/localization/en/IDScan.json'
import InfoTile from 'src/lib/localization/en/InfoTile.json'
import LastUpdatedAt from 'src/lib/localization/en/LastUpdatedAt.json'
import LeftDrawer from 'src/lib/localization/en/LeftDrawer.json'
import Liveness from 'src/lib/localization/en/Liveness.json'
import LivenessInfo from 'src/lib/localization/en/LivenessInfo.json'
import LoanApproved from 'src/lib/localization/en/LoanApproved.json'
import LoanCancelAmount from 'src/lib/localization/en/LoanCancelAmount.json'
import LoanCounterOffer from 'src/lib/localization/en/LoanCounterOffer.json'
import LoanFinalAccept from 'src/lib/localization/en/LoanFinalAccept.json'
import LoanHistory from 'src/lib/localization/en/LoanHistory.json'
import LoanPaymentReview from 'src/lib/localization/en/LoanPaymentReview.json'
import LoanSelection from 'src/lib/localization/en/LoanSelection.json'
import LoanAmountSelectionTemplate from 'src/lib/localization/en/LoanAmountSelectionTemplate.json'
import LoansMarketingSurvey from 'src/lib/localization/en/LoansMarketingSurvey.json'
import LoanSubmission from 'src/lib/localization/en/LoanSubmission.json'
import LoginTransition from 'src/lib/localization/en/LoginTransition.json'
import MaintenanceInProgress from 'src/lib/localization/en/MaintenanceInProgress.json'
import ManagePayments from 'src/lib/localization/en/ManagePayments.json'
import MigrationStatus from 'src/lib/localization/en/MigrationStatus.json'
import MissedPaymentsTile from 'src/lib/localization/en/MissedPaymentsTile.json'
import NetworkFailureError from 'src/lib/localization/en/NetworkFailureError.json'
import NotificationsPermissions from 'src/lib/localization/en/NotificationsPermissions.json'
import Onboarding from 'src/lib/localization/en/Onboarding.json'
import OpeningLandingPage from 'src/lib/localization/en/OpeningLandingPage.json'
import OTPVerification from 'src/lib/localization/en/OTPVerification.json'
import OverduePayment from 'src/lib/localization/en/OverduePayment.json'
import PaymentMethods from 'src/lib/localization/en/PaymentMethods.json'
import PayOverTimeConfirmation from 'src/lib/localization/en/PayOverTimeConfirmation.json'
import PayOverTimeFAQ from 'src/lib/localization/en/PayOverTimeFAQ.json'
import PersonalInfoConfirmation from 'src/lib/localization/en/PersonalInfoConfirmation.json'
import PersonalInformation from 'src/lib/localization/en/PersonalInformation.json'
import PFPasswordInput from 'src/lib/localization/en/PFPasswordInput.json'
import PhoneConfirmation from 'src/lib/localization/en/PhoneConfirmation.json'
import PhoneNumberEntry from 'src/lib/localization/en/PhoneNumberEntry.json'
import PhoneNumberInput from 'src/lib/localization/en/PhoneNumberInput.json'
import PhotoInstructionsCard from 'src/lib/localization/en/PhotoInstructionsCard.json'
import PossibleCard from 'src/lib/localization/en/PossibleCard.json'
import ProductSelection from 'src/lib/localization/en/ProductSelection.json'
import Profile from 'src/lib/localization/en/Profile.json'
import ProgressBar from 'src/lib/localization/en/ProgressBar.json'
import ReasonSurvey from 'src/lib/localization/en/ReasonSurvey.json'
import Redirection from 'src/lib/localization/en/Redirection.json'
import RegistrationOrLogin from 'src/lib/localization/en/RegistrationOrLogin.json'
import RelinkPreferredBankAccount from 'src/lib/localization/en/RelinkPreferredBankAccount.json'
import Reschedule from 'src/lib/localization/en/Reschedule.json'
import Security from 'src/lib/localization/en/Security.json'
import SetLoanAutoPayAccount from 'src/lib/localization/en/SetLoanAutoPayAccount.json'
import Settlements from 'src/lib/localization/en/Settlements.json'
import SetupAutopayTile from 'src/lib/localization/en/SetupAutopayTile.json'
import StateDisclosure from 'src/lib/localization/en/StateDisclosure.json'
import SurveyTemplate from 'src/lib/localization/en/SurveyTemplate.json'
import SuspendedLoan from 'src/lib/localization/en/SuspendedLoan.json'
import TemporaryPassword from 'src/lib/localization/en/TemporaryPassword.json'
import TransactionsAndPaymentsList from 'src/lib/localization/en/TransactionsAndPaymentsList.json'
import TransactionsTile from 'src/lib/localization/en/TransactionsTile.json'
import UnsupportedBanks from 'src/lib/localization/en/UnsupportedBanks.json'
import UnsupportedStateWaitList from 'src/lib/localization/en/UnsupportedStateWaitList.json'
import UpcomingPaymentsCard from 'src/lib/localization/en/UpcomingPaymentsCard.json'
import UpdateBinary from 'src/lib/localization/en/UpdateBinary.json'
import UpdaterScreen from 'src/lib/localization/en/UpdaterScreen.json'
import UpgradeToInstallment from 'src/lib/localization/en/UpgradeToInstallment.json'
import UserRequestedAction from 'src/lib/localization/en/UserRequestedAction.json'
import VerifyBankDetails from 'src/lib/localization/en/VerifyBankDetails.json'
import VerifyEmailLink from 'src/lib/localization/en/VerifyEmailLink.json'
import VerifyYourIdentity from 'src/lib/localization/en/VerifyYourIdentity.json'
import LoanApprovedExtendedPlan from 'src/lib/localization/en/LoanApprovedExtendedPlan.json'
import ZendeskHelpCenter from 'src/lib/localization/en/ZendeskHelpCenter.json'
import UnverifiedUserContactUs from 'src/lib/localization/en/UnverifiedUserContactUs.json'

// eslint-disable-next-line import/no-default-export
export default {
  AccountManagement,
  AccountsWithPossible,
  AccountOwnership,
  AccountRecovery,
  AddDebitCard,
  AdhocPayment,
  AdhocPaymentAmountEntry,
  AddBankLink,
  Advance,
  ApplicationProcessing,
  ApplicationSteps,
  ApplyInOneClick,
  ApplyInFewSteps,
  AppReviewManager,
  AttemptsList,
  Bank,
  BankAggregatorAccountSelection,
  BankAggregatorAccountSelectionForPrimary,
  BankFees,
  BankNumbersForm,
  BinaryVersionUpdate,
  CameraPage,
  CancelLoan,
  CardAcceptAgreement,
  CardActivatedTemplate,
  CardActivatePhysicalCard,
  CardActivatingPhysicalCard,
  CardActivation,
  CardAdhocPayment,
  CardAdhocPaymentAmountEntry,
  CardAdhocPaymentSuccess,
  CardAgreementViewer,
  CardAgreementBureau,
  CardAlert,
  CardApplicationFinancesTemplate,
  CardApplicationRejected,
  CardApprovedTemplate,
  CardAutopayOverviewTemplate,
  CardDashboard,
  CardDashboardEntryPointTiles,
  CardDashboardHeader,
  CardDashboardPayment,
  CardDashboardPaymentFooter,
  CardDashboardPayOverTime,
  CardDetails,
  CardHowDelinquencyAffectMe,
  CardHowPaymentsWork,
  CardHowPayOverTimeWorks,
  CardMakePaymentPOT,
  CardManualPaymentSchedule,
  CardManualPayOverviewTemplate,
  CardPaymentAgreement,
  CardPaymentScheduleTemplate,
  CardPayOverTimeDetails,
  CardPayOverTimeScheduleTemplate,
  CardPhysicalCardActivated,
  CardPhysicalCardActivationFailed,
  CardPOTOverdue,
  CardProductIntroduction,
  CardRescheduleUpcomingPayment,
  CardReviewAutopay,
  CardSetupAutopayments,
  CardsHistory,
  CardSignedUpAutopay,
  CardSubmitApplication,
  CardTransaction,
  CardUnlockedModal,
  CardVerifyBankDetails,
  CardViewPaymentMethods,
  CashAcceptAgreements,
  CashAmountSelection,
  CashHowPaymentsWork,
  CashOnTheWay,
  CashProductOverview,
  CashSelectDisbursementMethod,
  Common,
  ConfirmBankAccount,
  ConfirmDebitCard,
  ConfirmDeclineCounterOffer,
  ConfirmDetailsAcceptLoan,
  ContactUs,
  DashboardLoan,
  DashboardLoanAlertTile,
  DashboardLoanApprovedTile,
  DashboardLoanCalendarTile,
  DashboardLoanCancelledTile,
  DashboardLoanChargedOffSettledTile,
  DashboardLoanDecisioningDelayedTile,
  DashboardLoanDecisioningTile,
  DashboardLoanEligibleToApplyTile,
  DashboardLoanExpiredTile,
  DashboardLoanHistoryTile,
  DashboardLoanPaidOffTile,
  DashboardLoanProgressTile,
  DashboardLoanRejectedTile,
  DashboardLoanUnmetStateRequirementsTile,
  DashboardWhyChargedOffModal,
  DashboardWhyDefaultModal,
  DateBubble,
  DisbursementMethodSelection,
  Documents,
  DocumentsHistory,
  EmailConfirmation,
  EmailVerification,
  EmailPasswordForm,
  EmailPasswordManagement,
  ErrorAlertLightbox,
  Errors,
  ErrorNotice,
  LoanApprovedExtendedPlan,
  GoogleLogin,
  HowAutoPayWorksBottomSheet,
  HowDoesAutopayWork,
  HowPaymentsWork,
  IDScan,
  InfoTile,
  LastUpdatedAt,
  LeftDrawer,
  Liveness,
  LivenessInfo,
  LoanApproved,
  LoanCancelAmount,
  LoanCounterOffer,
  LoanFinalAccept,
  LoanHistory,
  LoanPaymentReview,
  LoanSelection,
  LoanAmountSelectionTemplate,
  LoansMarketingSurvey,
  LoanSubmission,
  LoginTransition,
  MaintenanceInProgress,
  ManagePayments,
  MigrationStatus,
  MissedPaymentsTile,
  NetworkFailureError,
  NotificationsPermissions,
  Onboarding,
  OpeningLandingPage,
  OTPVerification,
  OverduePayment,
  PaymentMethods,
  PayOverTimeConfirmation,
  PayOverTimeFAQ,
  PersonalInfoConfirmation,
  PersonalInformation,
  PFPasswordInput,
  PhoneConfirmation,
  PhoneNumberEntry,
  PhoneNumberInput,
  PhotoInstructionsCard,
  PossibleCard,
  ProductSelection,
  Profile,
  ProgressBar,
  ReasonSurvey,
  Redirection,
  RegistrationOrLogin,
  RelinkPreferredBankAccount,
  Reschedule,
  Security,
  SetLoanAutoPayAccount,
  Settlements,
  SetupAutopayTile,
  StateDisclosure,
  SurveyTemplate,
  SuspendedLoan,
  TemporaryPassword,
  TransactionsAndPaymentsList,
  TransactionsTile,
  UnsupportedBanks,
  UnsupportedStateWaitList,
  UpcomingPaymentsCard,
  UpdateBinary,
  UpdaterScreen,
  UpgradeToInstallment,
  UserRequestedAction,
  VerifyBankDetails,
  VerifyEmailLink,
  VerifyYourIdentity,
  ZendeskHelpCenter,
  UnverifiedUserContactUs,
}
