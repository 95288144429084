import {Consumer} from '@possible/cassandra'
import {LinkedAccount} from '@possible/cassandra/src/types/types.mobile.generated'
import {banking} from '@possible/generated/proto'

import {BankInfoAdded, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import AppNav from 'src/nav/AppNavActions'
import {aggregatorsId} from 'src/lib/user/userEnvConsts'
import {hasValidFundableAccountGql} from 'src/lib/user/utils'
import {MainStackNavigationProp, MainStackPages} from 'src/nav/MainStackParamsList'
import {showUnsupportedAccountsPopup} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/UnsupportedAccount'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

const yodleeComponent: MainStackPages = 'AggregatorYodlee'
const plaidComponent: MainStackPages = 'AggregatorPlaid'

const accountLinked = 'linked'
const accountLinkedInUse = 'linked_in_use'

export const CALLBACK_STATUS = Object.freeze({
  SUCCESS: 'success',
  UNSUPPORTED_BANK: 'unsupported_bank',
  BANK_REQUIRES_SWITCH: 'bank_requires_switch',
  ERROR: 'error',
  EXITED_SUCCESS: 'exited_success',
})
type BankAggregatorCompletedStatusKeys = keyof typeof CALLBACK_STATUS
export type BankAggregatorCompletedStatus =
  (typeof CALLBACK_STATUS)[BankAggregatorCompletedStatusKeys]

/**
 * Type guard to determine if a status string is one of BankAggregatorCompletedStatus type.
 */
export const isBankAggregatorCompletedStatus = (
  status: string,
): status is BankAggregatorCompletedStatus => {
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  return Object.values(CALLBACK_STATUS).includes(status as BankAggregatorCompletedStatus)
}

export function onBankLinkingCompleteGql<
  LinkedAccountSubset extends Pick<LinkedAccount, 'status' | 'isLoginRequired'>,
>(status: string, accounts: LinkedAccountSubset[] | undefined | null, bank: string): boolean {
  BankInfoAdded()
  switch (status) {
    case CALLBACK_STATUS.EXITED_SUCCESS:
      return true
    case CALLBACK_STATUS.SUCCESS:
      TrackAppEvent(AppEvents.Name.link_bank_account_successful, AppEvents.Category.Application, {
        bank: bank,
      })
      if (hasValidFundableAccountGql<LinkedAccountSubset>(accounts)) {
        return true
      } else {
        showUnsupportedAccountsPopup()
        return false
      }
    case CALLBACK_STATUS.ERROR:
      TrackAppEvent(AppEvents.Name.link_bank_account_failed, AppEvents.Category.Application, {
        bank: bank,
      })
      return false
    default:
      return false
  }
}

export const getAccountId = (account) => {
  if (account) {
    if (
      account.isLoginRequired === true &&
      (account.status === accountLinked || account.status === accountLinkedInUse)
    ) {
      return account.id
    }
  }
  return undefined
}

export const launchAggregatorWithPrefAccount = (
  env: Consumer.types.UserEnv | undefined,
  navigation,
  account,
  callback,
  switchCallback,
  // eslint-disable-next-line max-params
): void => {
  let aggregatorId: aggregatorsId = aggregatorsId.plaid
  if (env?.bankingAggregatorId === 'yodlee') {
    aggregatorId = aggregatorsId.yodlee
  }

  launchBankAggregator(aggregatorId, getAccountId(account), navigation, callback, switchCallback)
}

export type LaunchAggregatorOnCompleteCallback = (
  status: string,
  bank: string,
  linkedAccounts?: LinkedAccount[],
) => void | Promise<void>
export type LaunchAggregatorYodleeOnCompleteCallback = (success: boolean) => void
export type SwitchBankAggregatorCallback = (alternateId: string, accountId?: string) => void

export const convertAggregatorStringToType = (
  aggregatorIdStr: string | undefined | null,
): aggregatorsId | undefined => {
  if (aggregatorIdStr === aggregatorsId.plaid.valueOf()) {
    return aggregatorsId.plaid
  } else if (aggregatorIdStr === aggregatorsId.yodlee.valueOf()) {
    return aggregatorsId.yodlee
  } else if (aggregatorIdStr === aggregatorsId.mocked.valueOf()) {
    return aggregatorsId.mocked
  }
  return undefined
}

export const launchAggregator = (
  env: Consumer.types.UserEnv | undefined,
  navigation: MainStackNavigationProp,
  account: banking.ILinkedAccount | undefined | null,
  onComplete: LaunchAggregatorOnCompleteCallback | undefined,
  switchCallback: SwitchBankAggregatorCallback,
  // eslint-disable-next-line max-params
): void => {
  const alternateAggregator: aggregatorsId =
    convertAggregatorStringToType(env?.bankingAggregatorId) ?? aggregatorsId.plaid
  const aggregatorId: aggregatorsId =
    convertAggregatorStringToType(account?.aggregatorId) ?? alternateAggregator

  /* For Yodlee we just get a blob of data that we send to the server to decode so we don't have the name of the bank...*/

  const handleOnComplete =
    aggregatorId === aggregatorsId.yodlee
      ? (success: boolean): void =>
          void onComplete?.(success ? CALLBACK_STATUS.SUCCESS : CALLBACK_STATUS.ERROR, aggregatorId)
      : onComplete

  launchBankAggregator(aggregatorId, account, navigation, handleOnComplete, switchCallback)
}

const launchBankAggregator = (
  aggregatorId: aggregatorsId,
  account: LinkedAccount | banking.ILinkedAccount | undefined | null,
  navigation: MainStackNavigationProp,
  onComplete:
    | LaunchAggregatorOnCompleteCallback
    | LaunchAggregatorYodleeOnCompleteCallback
    | undefined,
  onSwitch: SwitchBankAggregatorCallback | undefined,
  // eslint-disable-next-line max-params
): void => {
  const props = {
    account,
    onComplete,
    onSwitch,
    windowObjectReference: undefined,
  }

  switch (aggregatorId) {
    case aggregatorsId.plaid:
    case aggregatorsId.mocked:
      AppNav.push(navigation, plaidComponent, {...props, accountId: account?.id})
      break
    case aggregatorsId.yodlee:
      AppNav.push(navigation, yodleeComponent, props)
      break
    default:
      logAddPaymentMethodErrorAndShowException(`Unsupported aggregator`)
  }
}

export const launchAlternateAggregator = (
  alternateId: string,
  accountId: string | undefined,
  navigation: MainStackNavigationProp,
  onComplete: LaunchAggregatorOnCompleteCallback | undefined,
  // eslint-disable-next-line max-params
): void => {
  const alternateAggregatorId: aggregatorsId | undefined =
    convertAggregatorStringToType(alternateId)
  if (alternateAggregatorId === aggregatorsId.yodlee) {
    AppNav.push(navigation, yodleeComponent, {
      // account id will probably be undefined for yodlee
      accountId,
      onComplete,
    })
  } else {
    logAddPaymentMethodErrorAndShowException(
      new Error(
        `BankAggregatorHelper launchAlternateAggregator() invalid alternateAggregatorId provided, alternateAggregatorId=${alternateAggregatorId}`,
      ),
    )
  }
}
