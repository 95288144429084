import React, {ReactElement, useCallback, useEffect, useState} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useFocusEffect} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'
import {ApolloError, ApolloQueryResult} from '@apollo/client'
import {useTranslation} from 'react-i18next'
import {View} from 'react-native'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import TileListTemplate from 'src/products/loans/components/templates/TileListTemplate/TileListTemplate'
import {
  DashboardLoanApproved,
  DashboardLoanApprovedProps,
} from 'src/products/loans/Dashboard/DashboardLoanApproved/DashboardLoanApproved'
import {getDashboardLoanApprovedProps} from 'src/products/loans/Dashboard/DashboardLoanApproved/DashboardLoanApproved.utils'
import {getDashboardLoanClosedProps} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed.utils'
import {DashboardLoanClosed} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed'
import {DashboardLoanClosedProps} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed.types'
import {DashboardLoanDecisioning} from 'src/products/loans/Dashboard/DashboardLoanDecisioning/DashboardLoanDecisioning'
import {DashboardLoanDecisioningProps} from 'src/products/loans/Dashboard/DashboardLoanDecisioning/DashboardLoanDecisioning.types'
import {getDashboardLoanDecisioningProps} from 'src/products/loans/Dashboard/DashboardLoanDecisioning/DashboardLoanDecisioning.utils'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {useDashboardLoanAggregateStatusQuery} from 'src/products/loans/Dashboard/DashboardLoan/queries/useDashboardLoanAggregateStatusQuery'
import {usePfDispatch} from 'src/store/utils'
import useRedirectToWeb from 'src/nav/useRedirectToWeb'
import {DashboardLoanActive} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive'
import {DashboardLoanActiveProps} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive.types'
import {getDashboardLoanActiveProps} from 'src/products/loans/Dashboard/DashboardLoanActive/DashboardLoanActive.utils'
import {
  loanDashboardLogError,
  loanDashboardLogErrorShowException,
} from 'src/products/loans/Dashboard/DashboardLoanUtils/DashboardLoan.utils'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {DashboardLoanAggregateStatusQuery} from 'src/products/loans/Dashboard/DashboardLoan/queries/DashboardLoanAggregateStatusQuery.gqls'
import {CardLoanDashboardTile} from 'src/products/card/LoanDash/CardLoanDashboardTile'
import {basicTileBorderRadius} from 'src/designSystem/guide'
import {useCardLoanDashboardTile} from 'src/products/card/LoanDash/UseCardLoanDashboardTile/useCardLoanDashboardTile'
import AppReviewManager from 'src/products/loans/Dashboard/AppReviewManager/AppReviewManager'
import * as DashboardLoanUtils from 'src/products/loans/Dashboard/DashboardLoanUtils/DashboardLoan.utils'
import ErrorTile from 'src/designSystem/components/organisms/ErrorTile/ErrorTile'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {LoanEligibleToApplyTile} from 'src/products/loans/Dashboard/LoanEligibleToApplyTile/LoanEligibleToApplyTile'
import {getRandomPollingInterval} from 'src/cassandra/src/utils'
import {LastUpdatedAt} from 'src/products/general/components/molecules/LastUpdatedAt/LastUpdatedAt'
import {LoanBrazeTiles} from 'src/products/loans/Dashboard/LoanBrazeTiles/LoanBrazeTiles'
import {usePromise} from 'src/lib/usePromise/usePromise'
import {EmailVerificationOverlay} from 'src/products/loans/EmailVerification/EmailVerificationOverlay/EmailVerificationOverlay'

type DashboardLoanProps = StackScreenProps<MainStackParamList, 'Dashboard'>

const PollInterval = 1000 * 60
const MaxAllowedConsecutiveQueryFailures = 2

/**
 * Entrypoint for the loan dashboard. This will query aggregate loan status and render the appropriate dashboard based on the status.
 */

const DashboardLoan: React.FC<DashboardLoanProps> = (props) => {
  const {navigation, route} = props
  const {t} = useTranslation('DashboardLoan')
  const dispatch = usePfDispatch()
  const shouldRedirectLoanApplyAcceptToWeb = useRedirectToWeb()

  const [aggregateLoanStatusPollingError, setAggregateLoanStatusPollingError] = useState<
    ApolloError | undefined
  >(undefined)
  const [consecutiveQueryFailureCount, setConsecutiveQueryFailureCount] = useState<number>(0)
  const [aggregateLoanStatusLastUpdatedAt, setAggregateLoanStatusLastUpdatedAt] = useState<
    Date | undefined
  >(undefined)
  const [isLoanActive, setIsLoanActive] = useState<boolean>(false)
  const [errorTileReason, setErrorTileReason] = useState<string>()

  const isContentCardsEnabled = useIsFeatureFlagEnabled('braze-content-cards')
  // determine if we should show the <CardLoanDashboardTile /> or not
  const {
    shouldShowCardLoanDashboardTile,
    canAccessCards,
    hadOpportunityToApplyForCard,
    isLoading: isLoadingCardUserStatus,
  } = useCardLoanDashboardTile()

  // set up aggregate loan status query polling
  const {
    selectedData: selectedDataAggregateLoanStatus,
    data: dataAggregateLoanStatus,
    refetch: refetchAggregateLoanStatus,
    startPolling: startAggregateLoanStatusPolling,
    stopPolling: stopAggregateLoanStatusPolling,
    loading: isAggregateLoanStatusLoading,
    networkStatus: aggregateLoanStatusNetworkStatus,
  } = useDashboardLoanAggregateStatusQuery({
    pollInterval: PollInterval,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (): void => {
      setAggregateLoanStatusLastUpdatedAt(new Date())
      setConsecutiveQueryFailureCount(0)
      setAggregateLoanStatusPollingError(undefined)
    },
    onError: (error: ApolloError): void => {
      loanDashboardLogError(
        error,
        `aggregate loan status query failed, consecutiveQueryFailureCount=${
          consecutiveQueryFailureCount + 1
        } errorGraphQLErrorPath=${
          error?.graphQLErrors[0]?.path ? error?.graphQLErrors[0]?.path.join(' ') : 'none'
        } errorGraphqlErrorCode=${
          typeof error?.graphQLErrors[0]?.extensions?.errorCode === 'string'
            ? error?.graphQLErrors[0]?.extensions?.errorCode
            : 'none'
        }`,
      )
      setConsecutiveQueryFailureCount((prevCount) => prevCount + 1)
      if (consecutiveQueryFailureCount + 1 > MaxAllowedConsecutiveQueryFailures) {
        // network requests fail often and typically recover in the next request, so we allow a few consecutive failures before showing an error tile
        // as long as we have previous cached aggregate loan status data to display
        setAggregateLoanStatusPollingError(error)
      }
    },
  })

  const [handleApplyForNewLoan, {isLoading: isApplyLoading}] = usePromise(
    async (): Promise<void> => {
      await DashboardLoanUtils.onReapplyForNewLoan({
        navigation,
        dispatch,
        shouldRedirectLoanApplyAndAcceptToWeb: shouldRedirectLoanApplyAcceptToWeb,
        loanOffers: selectedDataAggregateLoanStatus?.loanOffers ?? [],
      })
    },
  )

  useEffect(() => {
    startAggregateLoanStatusPolling(getRandomPollingInterval(PollInterval))
    return (): void => {
      stopAggregateLoanStatusPolling()
    }
  }, [startAggregateLoanStatusPolling, stopAggregateLoanStatusPolling])

  const [isReloadingAggregateStatusAfterFocus, setIsReloadingAggregateStatusAfterFocus] =
    useState<boolean>(false)
  useFocusEffect(
    useCallback(() => {
      // when the screen is re-focused we re-fetch the loan aggregate status to immediately reflect the change.
      // for example, if user is arriving back on the screen after being sent to a screen to accept their loan offer
      // or a modal to cancel their loan offer
      setIsReloadingAggregateStatusAfterFocus(true)
      const promiseResult:
        | Promise<ApolloQueryResult<DashboardLoanAggregateStatusQuery>>
        | undefined = refetchAggregateLoanStatus()
      // on local dev this result is sometimes undefined due to HMR weirdness so we do a safety check first
      if (typeof promiseResult === 'undefined') {
        loanDashboardLogErrorShowException(
          new Error('unable to re-fetch aggregate loan status data, fetch result was undefined'),
        )
        // clear loading state immediately if it didn't re-fetch
        setIsReloadingAggregateStatusAfterFocus(false)
      } else {
        promiseResult
          .catch((e) => {
            loanDashboardLogErrorShowException(
              e instanceof Error ? e : new Error(String(e)),
              'failed to re-fetch aggregate loan status data after being re-focused',
            )
          })
          .finally(() => {
            // clear loading state after re-fetch is done
            setIsReloadingAggregateStatusAfterFocus(false)
          })
      }
      // also refetch redux data that is necessary for some other screens like applying for a loan
      // or updating payment dates
      void dispatch(UserStateRefresh())
    }, [refetchAggregateLoanStatus, dispatch]),
  )

  const hasLoanStatusData = !!(
    selectedDataAggregateLoanStatus?.decisioningLoanAggregateStatus ||
    selectedDataAggregateLoanStatus?.approvedLoanAggregateStatus ||
    selectedDataAggregateLoanStatus?.activeLoanAggregateStatus ||
    selectedDataAggregateLoanStatus?.closedLoanAggregateStatus
  )

  const isCardUserWithoutPreviousLoan = hadOpportunityToApplyForCard && !hasLoanStatusData

  // when we return to this screen we want to check if the user has an active loan
  // we need to do this as a focus effect instead of just an effect because the
  // activation flow will update the cache and, in CA, the upgrade to installment
  // screen will be ABOVE this one and thusly the app review modal will be shown
  // on top of the upgrade to installment prompt and that is not our desired UX.
  useFocusEffect(
    useCallback(() => {
      setIsLoanActive(!!selectedDataAggregateLoanStatus?.activeLoanAggregateStatus)
    }, [selectedDataAggregateLoanStatus?.activeLoanAggregateStatus]),
  )

  const shouldSeeLoanDashboardElement = !isCardUserWithoutPreviousLoan

  // tiles that that should be shown on all dashboards
  const tilesForAllDashboards: ReactElement[] = []

  // when loading initial loan data (before dashboard has been shown) we show a loading spinner
  // we also show a spinner if the data is re-loading when re-focusing back to this screen after coming from somewhere else (such as applying for a loan)
  const isLoadingInitialLoanData =
    isAggregateLoanStatusLoading &&
    !selectedDataAggregateLoanStatus?.approvedLoanAggregateStatus &&
    !selectedDataAggregateLoanStatus?.closedLoanAggregateStatus &&
    !selectedDataAggregateLoanStatus?.decisioningLoanAggregateStatus &&
    !selectedDataAggregateLoanStatus?.activeLoanAggregateStatus

  // loan data loading state
  if (isLoadingInitialLoanData || isReloadingAggregateStatusAfterFocus || isLoadingCardUserStatus) {
    const loadingElements: ReactElement[] = [
      ...tilesForAllDashboards,
      <>
        <Box marginTop="large" testID="DashboardLoan-Loading">
          <Loading type="loader0" size="large" />
        </Box>
      </>,
    ]
    return (
      <ErrorBoundary
        fallback={<View /> /*if this fails display nothing*/}
        onError={(err: Error): void => {
          loanDashboardLogError(err, 'loading state failed to render')
        }}
      >
        <TileListTemplate list={loadingElements} paddingSide="medium" listViewMarginTop={0} />
      </ErrorBoundary>
    )
  }

  // ContentCards lets us use the Braze tool to show dynamic content to users
  const brazeContentCardsTile: ReactElement = (
    <ErrorBoundary
      fallback={<View /> /*if this fails display nothing*/}
      onError={(err: Error): void => {
        loanDashboardLogError(err, 'Braze Content Cards failed to render')
      }}
    >
      <LoanBrazeTiles />
    </ErrorBoundary>
  )
  if (isContentCardsEnabled) {
    tilesForAllDashboards.push(brazeContentCardsTile)
  }

  // we show the CardLoanDashboardTile for card applications it if the the user does NOT have an active
  // loan, and if the hook decides this user can be shown the cards tile given their card offer status
  const shouldShowCardLoanDashboardTileToLoanUser =
    shouldShowCardLoanDashboardTile && !selectedDataAggregateLoanStatus?.activeLoanAggregateStatus
  if (shouldShowCardLoanDashboardTileToLoanUser) {
    // this allows user to apply for the Possible card and track status
    const cardsApplicationTile = (
      <ErrorBoundary
        fallback={
          <ErrorTile
            testID="DashboardLoan-CardErrorTile"
            title={t('ErrorTileCardFailedTitle')}
            onContactUs={(): void => {
              DashboardLoanUtils.onContactUs({navigation})
            }}
          />
        }
        onError={(err: Error): void => {
          TrackAppEvent(
            ManageActiveLoanEvents.error_loan_dashboard_viewed,
            AppEvents.Category.ManageActiveLoan,
            {
              source: 'CardLoanDashboardTile failed',
            },
          )
          loanDashboardLogError(err, 'CardLoanDashboardTile failed to render')
        }}
      >
        <CardLoanDashboardTile
          navigation={navigation}
          tileMarginHorizontal={0}
          tileRadius={basicTileBorderRadius}
        />
      </ErrorBoundary>
    )
    tilesForAllDashboards.push(cardsApplicationTile)
  }

  const handleOnRetryErrorTile = async (): Promise<void> => {
    await refetchAggregateLoanStatus()
  }
  // render the appropriate loan dashboard
  const loanDashboardErrorTile = (
    <ErrorTile
      testID="DashboardLoan-LoanErrorTile"
      title={t('ErrorTileLoanFailedTitle')}
      onContactUs={(): void => {
        DashboardLoanUtils.onContactUs({navigation})
      }}
      onRetry={handleOnRetryErrorTile}
    />
  )

  let loanDashboardElement: ReactElement | undefined
  // approved dashboard
  if (
    selectedDataAggregateLoanStatus?.approvedLoanAggregateStatus &&
    !aggregateLoanStatusPollingError
  ) {
    const approvedProps: DashboardLoanApprovedProps = getDashboardLoanApprovedProps({
      approvedLoanDashboardData: {
        userProfile: selectedDataAggregateLoanStatus.userProfile,
        approvedLoanAggregateStatus: selectedDataAggregateLoanStatus.approvedLoanAggregateStatus,
        loanTypeVariant: selectedDataAggregateLoanStatus.loanTypeVariant,
      },
      navigation,
      shouldRedirectLoanApplyAndAcceptToWeb: shouldRedirectLoanApplyAcceptToWeb,
    })
    loanDashboardElement = (
      <ErrorBoundary
        fallback={loanDashboardErrorTile}
        onError={(err: Error): void => {
          TrackAppEvent(
            ManageActiveLoanEvents.error_loan_dashboard_viewed,
            AppEvents.Category.ManageActiveLoan,
            {
              source: 'DashboardLoanApproved failed',
            },
          )
          loanDashboardLogError(err, 'approved loan dashboard failed to render')
        }}
      >
        <DashboardLoanApproved {...approvedProps} />
      </ErrorBoundary>
    )
  }

  // closed loan dashboard
  if (
    selectedDataAggregateLoanStatus?.closedLoanAggregateStatus &&
    !aggregateLoanStatusPollingError
  ) {
    const closedProps: DashboardLoanClosedProps = getDashboardLoanClosedProps({
      navigation,
      closedLoanDashboardData: {
        closedLoanAggregateStatus: selectedDataAggregateLoanStatus.closedLoanAggregateStatus,
        userProfile: selectedDataAggregateLoanStatus.userProfile,
      },
      dispatch,
      shouldRedirectLoanApplyAndAcceptToWeb: shouldRedirectLoanApplyAcceptToWeb,
      loanOffers: selectedDataAggregateLoanStatus?.loanOffers ?? [],
    })
    loanDashboardElement = (
      <ErrorBoundary
        fallback={loanDashboardErrorTile}
        onError={(err: Error): void => {
          TrackAppEvent(
            ManageActiveLoanEvents.error_loan_dashboard_viewed,
            AppEvents.Category.ManageActiveLoan,
            {
              source: 'DashboardLoanClosed failed',
            },
          )
          loanDashboardLogError(err, 'closed loan dashboard failed to render')
        }}
      >
        <DashboardLoanClosed {...closedProps} />
      </ErrorBoundary>
    )
  }

  // decisioning loan dashboard
  if (
    selectedDataAggregateLoanStatus?.decisioningLoanAggregateStatus &&
    !aggregateLoanStatusPollingError
  ) {
    const decisioningProps: DashboardLoanDecisioningProps = getDashboardLoanDecisioningProps({
      decisioningLoanDashboardData: {
        decisioningLoanAggregateStatus:
          selectedDataAggregateLoanStatus.decisioningLoanAggregateStatus,
        userProfile: selectedDataAggregateLoanStatus.userProfile,
        loanTypeVariant: selectedDataAggregateLoanStatus.loanTypeVariant,
      },
    })

    loanDashboardElement = (
      <ErrorBoundary
        fallback={loanDashboardErrorTile}
        onError={(err: Error): void => {
          TrackAppEvent(
            ManageActiveLoanEvents.error_loan_dashboard_viewed,
            AppEvents.Category.ManageActiveLoan,
            {
              source: 'DashboardLoanDecisioning failed',
            },
          )
          loanDashboardLogError(err, 'decisioning loan dashboard failed to render')
        }}
      >
        <DashboardLoanDecisioning {...decisioningProps} />
      </ErrorBoundary>
    )
  }

  // active loan dashboard
  if (
    selectedDataAggregateLoanStatus?.activeLoanAggregateStatus &&
    !aggregateLoanStatusPollingError
  ) {
    const activeProps: DashboardLoanActiveProps = getDashboardLoanActiveProps({
      activeLoanDashboardData: {
        activeLoanAggregateStatus: selectedDataAggregateLoanStatus.activeLoanAggregateStatus,
        loanTypeVariant: selectedDataAggregateLoanStatus.loanTypeVariant,
      },
      dispatch,
      navigation,
      loanId: dataAggregateLoanStatus?.me.loans.latestActionableLoan?.id,
    })
    loanDashboardElement = (
      <ErrorBoundary
        fallback={loanDashboardErrorTile}
        onError={(err: Error): void => {
          TrackAppEvent(
            ManageActiveLoanEvents.error_loan_dashboard_viewed,
            AppEvents.Category.ManageActiveLoan,
            {
              source: 'DashboardLoanActive failed',
            },
          )
          loanDashboardLogError(err, 'active loan dashboard failed to render')
        }}
      >
        <DashboardLoanActive {...activeProps} />
      </ErrorBoundary>
    )
  }

  // UndefinedLoanAggregateStatus should never happen but log if it does
  if (selectedDataAggregateLoanStatus?.undefinedLoanAggregateStatus) {
    loanDashboardLogError(
      new Error('loan dashboard got UndefinedLoanAggregateStatus, no dashboard displayed'),
    )
  }

  // if this user has no loan data but was previously a card user they are probably a rejected card user.
  // we should let them apply for a loan
  if (!loanDashboardElement && isCardUserWithoutPreviousLoan) {
    tilesForAllDashboards.push(
      <LoanEligibleToApplyTile
        testID="DashboardLoan-CardUser-LoanEligibleToApplyTile"
        onApplyForNewLoan={async (): Promise<void> => {
          await handleApplyForNewLoan()
        }}
        isLoading={isApplyLoading}
      />,
    )
  }

  // if this user has no loan dashboard element then this is an error
  // (unless they were a previous card user in which case they won't have loan data and that's expected)
  if (!loanDashboardElement && shouldSeeLoanDashboardElement) {
    // the type of loan status according to the selected data
    let selectedDataLoanStatusType:
      | 'decisioning'
      | 'approved'
      | 'active'
      | 'closed'
      | 'undefined'
      | 'none'
    if (selectedDataAggregateLoanStatus?.decisioningLoanAggregateStatus) {
      selectedDataLoanStatusType = 'decisioning'
    } else if (selectedDataAggregateLoanStatus?.approvedLoanAggregateStatus) {
      selectedDataLoanStatusType = 'approved'
    } else if (selectedDataAggregateLoanStatus?.closedLoanAggregateStatus) {
      selectedDataLoanStatusType = 'closed'
    } else if (selectedDataAggregateLoanStatus?.activeLoanAggregateStatus) {
      selectedDataLoanStatusType = 'active'
    } else if (selectedDataAggregateLoanStatus?.undefinedLoanAggregateStatus) {
      selectedDataLoanStatusType = 'undefined'
    } else {
      selectedDataLoanStatusType = 'none'
    }

    let networkErrorStatusCode: number | undefined
    if (
      aggregateLoanStatusPollingError?.networkError &&
      // the networkError is a union of 3 types and only one has statusCode so we must check for it first
      'statusCode' in aggregateLoanStatusPollingError.networkError
    ) {
      networkErrorStatusCode = aggregateLoanStatusPollingError.networkError.statusCode
    }

    const gqlErrorCode: string =
      typeof aggregateLoanStatusPollingError?.graphQLErrors[0]?.extensions?.errorCode === 'string'
        ? aggregateLoanStatusPollingError?.graphQLErrors[0]?.extensions?.errorCode
        : ''
    // this shouldn't happen so if it does we log a lot of info to assist with debugging
    loanDashboardLogError(
      new Error(
        `no loan dashboard element created, aggregate status not found, aggregateLoanStatusPollingError=${
          aggregateLoanStatusPollingError ? aggregateLoanStatusPollingError.message : 'false'
        } aggregateLoanStatusPollingErrorNetworkErrorMessage=${
          aggregateLoanStatusPollingError?.networkError
            ? aggregateLoanStatusPollingError.networkError.message
            : 'none'
        } aggregateLoanStatusPollingErrorNetworkErrorStatusCode=${
          networkErrorStatusCode ? networkErrorStatusCode : 'none'
        } aggregateLoanStatusPollingErrorGraphQLErrorMessage=${
          aggregateLoanStatusPollingError?.graphQLErrors?.length
            ? aggregateLoanStatusPollingError?.graphQLErrors[0].message
            : 'none'
        } aggregateLoanStatusPollingErrorGraphQLErrorPath=${
          aggregateLoanStatusPollingError?.graphQLErrors[0]?.path
            ? aggregateLoanStatusPollingError?.graphQLErrors[0]?.path.join(' ')
            : 'none'
        } aggregateLoanStatusPollingErrorGraphQLErrorCode=${
          gqlErrorCode ? gqlErrorCode : 'none'
        } isAggregateLoanStatusLoading=${
          isAggregateLoanStatusLoading ? 'true' : 'false'
        } isReloadingAggregateStatusAfterFocus=${
          isReloadingAggregateStatusAfterFocus ? 'true' : 'false'
        } isUserProfileDataAvailable=${
          selectedDataAggregateLoanStatus?.userProfile ? 'true' : 'false'
        } aggregateLoanStatusNetworkStatus=${aggregateLoanStatusNetworkStatus} rawDataLatestActionableLoanTypename=${
          dataAggregateLoanStatus?.me.loans.latestActionableLoan?.aggregateStatus?.__typename ??
          'none'
        } aggregateStatusSelectedDataType=${selectedDataLoanStatusType} canAccessCards=${
          canAccessCards ? 'true' : 'false'
        } hadOpportunityToApplyForCard=${
          hadOpportunityToApplyForCard ? 'true' : 'false'
        } consecutiveQueryFailureCount=${consecutiveQueryFailureCount}`,
      ),
    )
  }

  // add ErrorTile instances for any data failure scenarios
  const errorTiles: ReactElement[] = []
  // if aggregate status failed to load and we have no cached previous data to use, we display an error
  const didQueryFailWithNoCachedDataAvailable =
    consecutiveQueryFailureCount > 0 && !selectedDataAggregateLoanStatus
  // if aggregate status failed to load and the dashboard can't be displayed we show an error
  const queryFailedCantDisplayDashboard =
    aggregateLoanStatusPollingError || (!loanDashboardElement && shouldSeeLoanDashboardElement)

  // record reasons that we show an error screen so that we can track w/ analytics and log in a useEffect()
  if (didQueryFailWithNoCachedDataAvailable || queryFailedCantDisplayDashboard) {
    errorTiles.push(loanDashboardErrorTile)
    let currentErrorReason = ''
    if (didQueryFailWithNoCachedDataAvailable) {
      currentErrorReason = 'Query failed with no cached data available to fallback to'
    } else if (queryFailedCantDisplayDashboard) {
      currentErrorReason = 'Dashboard failed to render when it should be shown.'
      if (aggregateLoanStatusPollingError) {
        currentErrorReason += ' Query failed too many times consecutively'
      }
    }
    if (errorTileReason !== currentErrorReason) {
      // we are setting this state so that we don't re-track this error again next
      // render if the same error is still here
      setErrorTileReason(currentErrorReason)
      TrackAppEvent(
        ManageActiveLoanEvents.error_loan_dashboard_viewed,
        AppEvents.Category.ManageActiveLoan,
        {
          source: 'Error tile shown: ' + currentErrorReason,
        },
      )
      DashboardLoanUtils.loanDashboardLogError(
        new Error(`Displaying error tile for the following reason: ${errorTileReason}`),
      )
    }
  }

  // construct the final elements list
  let finalElementsList: ReactElement[] = []
  if (tilesForAllDashboards.length > 0) {
    finalElementsList = finalElementsList.concat(tilesForAllDashboards)
  }
  if (errorTiles.length > 0) {
    finalElementsList = finalElementsList.concat(errorTiles)
  } else {
    // clear error tile reason if there is no error anymore
    if (errorTileReason) {
      setErrorTileReason(undefined)
    }
  }

  if (loanDashboardElement) {
    finalElementsList = finalElementsList.concat(loanDashboardElement)
  }
  if (aggregateLoanStatusLastUpdatedAt && !aggregateLoanStatusPollingError) {
    finalElementsList.push(
      <LastUpdatedAt
        time={aggregateLoanStatusLastUpdatedAt}
        onRefresh={async (): Promise<void> => {
          await refetchAggregateLoanStatus()
        }}
      />,
    )
  }

  return (
    <>
      {/* modal to ask users to review the app. dont include in list for TileListTemplate so that it doesnt add an empty gap */}
      <ErrorBoundary
        fallback={<View /> /*if this fails display nothing*/}
        onError={(err: Error): void => {
          TrackAppEvent(
            ManageActiveLoanEvents.error_loan_dashboard_viewed,
            AppEvents.Category.ManageActiveLoan,
            {
              source: 'AppReviewManager failed',
            },
          )
          loanDashboardLogError(err, 'AppReviewManager failed to render')
        }}
      >
        <AppReviewManager isLoanActive={isLoanActive} />
      </ErrorBoundary>

      {/* display the page of tiles */}
      <TileListTemplate
        list={finalElementsList}
        paddingSide="medium"
        paddingBottom="medium"
        listViewMarginTop={0}
        headerSpacerWebMultiplierOverride={1.5}
      />
      {selectedDataAggregateLoanStatus?.userProfile?.email?.verified === false ? (
        <EmailVerificationOverlay
          email={selectedDataAggregateLoanStatus.userProfile.email?.address ?? undefined}
          isEmailVerified={selectedDataAggregateLoanStatus.userProfile.email.verified}
          isForEmailChange={route?.params?.isForEmailChange}
        />
      ) : null}
    </>
  )
}

export {DashboardLoan}
