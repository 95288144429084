import DeviceInfo from 'react-native-device-info'

import {
  EVENT_ADD_PAYMENT_INFO,
  EVENT_ADD_TO_WISHLIST,
  EVENT_CARD_ACTIVATED,
  EVENT_CARD_APPROVED,
  EVENT_COMPLETE_REGISTRATION,
  EVENT_COMPLETE_TUTORIAL,
  EVENT_INITIATE_PURCHASE,
  EVENT_PURCHASE,
  EVENT_UNLOCK_ACHIEVEMENT,
} from 'src/lib/Analytics/marketingEvents'
import {BrazeInstance, newBraze} from 'src/lib/braze/braze'

import {AppEvents, AppEventCategory, AppEventName, brazeEvents} from 'src/lib/Analytics/app_events'
import {devModeInfo, readDevMode} from 'src/lib/devMode'
import FBSDK from 'src/lib/FBSDK'
import Firebase from 'src/lib/firebase'
import Log from 'src/lib/loggingUtil'
import SingularSDK from 'src/lib/singular'
import TTSDK from 'src/lib/TTSDK/ttsdk'

import {PfReduxStore} from 'src/store/types'

import {AnalyticsDisabled} from 'src/lib/Analytics/Analytics.utils'
import EventStreamSingleton from 'src/lib/EventStream/EventStream'
import {AmplitudeService} from 'src/lib/Services/Amplitude/AmplitudeService'
import {executeDeeplinkActions, initDeeplinks} from 'src/lib/singular/utils'
import {isDeviceAndroid, isDeviceIOS, isDeviceNotWeb, isDeviceWeb} from 'src/lib/utils/platform'
import {DatadogService} from 'src/lib/Services/Datadog/DatadogService'
import {AmplitudeClientInstance} from 'src/lib/Analytics/amplitude'
import {AnalyticDataObject} from 'src/lib/EventStream/EventStream.types'

const brazeAPIKeyProdWeb = '5cd0ad76-939b-49e8-9dd1-8b323c25ba71'
const brazeAPIKeyStagingWeb = 'aa45d4c4-34b0-4b4c-a603-3b526443815c'
const brazeAPIKeyProdAndroid = '799b3931-d7c5-4df5-9408-e63b1cb64b08'
const brazeAPIKeyStagingAndroid = '0ccee022-bfd6-460a-90c1-2538b0f88488'
const singularSecret = '0a63fd4211eb6ff5ae47104505fb0250'
const singularAPIKey = 'possible_finance_inc__4772c943'
const googleAnalyticsSendToAccount = 'AW-804760415/f7K4CK31_ooDEN_W3v8C'

const {AppEventsLogger: FBAppEventsLogger} = FBSDK
const {AppEventsLogger: TTAppEventsLogger} = TTSDK

const GenericEventLogin = 'LOGIN'
const SingularLogin = 'sng_login'
const GenericEventSessionStart = 'SESSION_START'

const FbEventAddPaymentInfo = 'fb_mobile_add_payment_info'
const FbEventAddToWishlist = 'fb_mobile_add_to_wishlist'
const FbEventCompleteRegistration = 'fb_mobile_complete_registration'
const FbEventTutorialComplete = 'fb_mobile_tutorial_completion'
const FbEventInitiatedCheckout = 'fb_mobile_initiated_checkout'
const FbEventAchievementUnlocked = 'fb_mobile_achievement_unlocked'
const FbEventLogin = 'fb_mobile_login'

const FbEventSubmitCardApplication = 'ViewContent'
const TtEventSubmitCardApplication = 'ViewContent'

export async function _initSingular() {
  if (isDeviceNotWeb()) {
    await initDeeplinks()
  }
  SingularSDK.init(singularSecret, singularAPIKey)
}

const getBrazeApiKey = (): string => {
  if (isDeviceWeb()) {
    return devModeInfo.devMode ? brazeAPIKeyStagingWeb : brazeAPIKeyProdWeb
  } else if (isDeviceAndroid()) {
    return devModeInfo.devMode ? brazeAPIKeyStagingAndroid : brazeAPIKeyProdAndroid
  } else {
    return ''
  }
}

export class Analytics {
  store: PfReduxStore | undefined = undefined

  _amplitudeService: AmplitudeService = new AmplitudeService()
  _datadogService: DatadogService = new DatadogService()

  _braze: BrazeInstance | undefined = undefined
  userId: string | undefined = undefined
  deviceId: string | undefined = undefined
  isDevMode: boolean | undefined = undefined

  // eslint-disable-next-line @typescript-eslint/require-await
  async _initDatadog(): Promise<void> {
    void this._datadogService.init()
    this._datadogService.startListening()
  }

  _getBraze(): BrazeInstance | null {
    if (this._braze) {
      return this._braze
    }

    /*
      devMode needs to be refactored, we have an awkward use of string 'DEV' and false. But
      for now the following check will allow braze events from production.
     */

    if (devModeInfo.devMode === undefined || devModeInfo.devMode === null) {
      //we're not initiated yet
      return null
    }

    this._braze = newBraze(getBrazeApiKey())

    return this._braze
  }
  // eslint-disable-next-line @typescript-eslint/require-await
  async _initAmplitudeClient(): Promise<void> {
    void this._amplitudeService.init()
    this._amplitudeService.startListening()
  }

  _getAmplitudeClient(): AmplitudeClientInstance | undefined {
    return this._amplitudeService.amplitudeClient
  }

  async init(store: PfReduxStore): Promise<void> {
    if (this.store) {
      return
    }

    this.store = store
    this.isDevMode = await readDevMode()

    if (isDeviceIOS()) {
      this.deviceId = DeviceInfo.getUniqueIdSync()
      EventStreamSingleton.emit({type: 'metadata', data: {deviceId: this.deviceId}})
    }

    /* We want to do this before we `loadUrchinQuery` because that will remove those params from the location bar */
    executeDeeplinkActions()
    await this._initAmplitudeClient()
  }

  SetAmplitudeUserProperties(property: string, value: string): void {
    EventStreamSingleton.emit({type: 'metadata', data: {[property]: value}})
  }

  SetBrazeUserProperties(
    key: string,
    value: number | boolean | string | string[] | Date | null,
  ): void {
    if (isDeviceWeb()) {
      // @ts-expect-error getUser() only exists in the .web. this should probably be refactored to have platform-specific stuff only in the .web file
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      this._getBraze()?.getUser()?.setCustomUserAttribute(key, value)
    } else {
      this._getBraze()?.setCustomUserAttribute(key, value)
    }
  }

  flush(): void {
    try {
      if (AnalyticsDisabled()) return
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  SetUserId(userId: string): void {
    try {
      if (AnalyticsDisabled()) return

      EventStreamSingleton.emit({type: 'metadata', data: {userId, deviceId: this.deviceId}})

      this.userId = userId

      SingularSDK.setIdentity(userId)

      this._getBraze()?.changeUser(userId)

      void Firebase.setUserId(userId)
      this.SessionStart()
    } catch (e) {
      Log.warn('Analytics, SetUserId exception: ', e)
    }
  }

  ClearId(): void {
    try {
      if (AnalyticsDisabled()) return

      EventStreamSingleton.emit({type: 'metadata', data: {userId: undefined}})

      void Firebase.setUserId(null)
      SingularSDK.unsetUserId()
    } catch (e) {
      Log.warn('Analytics, ClearId exception: ', e)
    }
  }

  BankInfoAdded(): void {
    try {
      if (AnalyticsDisabled()) return
      FBAppEventsLogger.logEvent(FbEventAddPaymentInfo)
      SingularSDK.logEvent(EVENT_ADD_PAYMENT_INFO)
      void Firebase.logEvent(EVENT_ADD_PAYMENT_INFO)
      Log.info(FbEventAddPaymentInfo)
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  CompletedVerifyInfo(): void {
    try {
      if (AnalyticsDisabled()) return
      FBAppEventsLogger.logEvent(FbEventAddToWishlist)
      SingularSDK.logEvent(EVENT_ADD_TO_WISHLIST)
      void Firebase.logEvent(EVENT_ADD_TO_WISHLIST)
      Log.info(EVENT_ADD_TO_WISHLIST)
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  ApplicationSubmitted(loanCount: number): void {
    try {
      if (AnalyticsDisabled()) return

      FBAppEventsLogger.logEvent(FbEventCompleteRegistration, {type: 'loan', loanCount})
      SingularSDK.logEvent(EVENT_COMPLETE_REGISTRATION, {
        customData: {loanCount: `${loanCount}`},
      })

      void Firebase.logEvent(EVENT_COMPLETE_REGISTRATION, {loanCount})
      Log.info(EVENT_COMPLETE_REGISTRATION, {loanCount})
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  CardApplicationSubmitted(): void {
    try {
      if (AnalyticsDisabled()) return

      const params = {
        content_type: 'product',
        content_name: 'card_application_submitted',
      }
      FBAppEventsLogger.logEvent(FbEventSubmitCardApplication, params)
      TTAppEventsLogger.logEvent(TtEventSubmitCardApplication, params)
      Log.info(
        `${TtEventSubmitCardApplication} & ${FbEventSubmitCardApplication} with params: ${JSON.stringify(
          params,
        )}`,
      )
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  LoanSelectionCompleted(): void {
    try {
      if (AnalyticsDisabled()) return
      FBAppEventsLogger.logEvent(FbEventTutorialComplete)
      SingularSDK.logEvent(EVENT_COMPLETE_TUTORIAL)
      void Firebase.logEvent(EVENT_COMPLETE_TUTORIAL)
      Log.info(EVENT_COMPLETE_TUTORIAL)
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  LoanApproved(): void {
    try {
      if (AnalyticsDisabled()) return
      FBAppEventsLogger.logEvent(FbEventInitiatedCheckout, {type: 'loan'})
      SingularSDK.logEvent(EVENT_INITIATE_PURCHASE)
      void Firebase.logEvent(EVENT_INITIATE_PURCHASE)
      Log.info(EVENT_INITIATE_PURCHASE)
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  LoanAccepted({userId}: {userId: string}): void {
    if (AnalyticsDisabled()) return
    try {
      FBAppEventsLogger.logPurchase('USD', {user_id: userId})
      SingularSDK.logEvent(EVENT_PURCHASE)
      void Firebase.logEvent(EVENT_PURCHASE)
      Log.info(EVENT_PURCHASE)
      if (isDeviceWeb()) {
        gtag('event', 'conversion', {
          send_to: googleAnalyticsSendToAccount,
        })
      }
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  CardApproved(): void {
    if (AnalyticsDisabled()) return
    try {
      if (!this.isDevMode) {
        FBAppEventsLogger.logEvent(EVENT_CARD_APPROVED)
        TTAppEventsLogger.logEvent(EVENT_CARD_APPROVED)
      }
      SingularSDK.logEvent(EVENT_CARD_APPROVED)
      void Firebase.logEvent(EVENT_CARD_APPROVED)
      Log.info(EVENT_CARD_APPROVED)
      if (isDeviceWeb()) {
        gtag('event', EVENT_CARD_APPROVED, {
          send_to: googleAnalyticsSendToAccount,
        })
      }
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }
  CardActivated(): void {
    if (AnalyticsDisabled()) return
    try {
      if (!this.isDevMode) {
        FBAppEventsLogger.logEvent(EVENT_CARD_ACTIVATED)
        TTAppEventsLogger.logEvent(EVENT_CARD_ACTIVATED)
      }
      SingularSDK.logEvent(EVENT_CARD_ACTIVATED)
      void Firebase.logEvent(EVENT_CARD_ACTIVATED)
      Log.info(EVENT_CARD_ACTIVATED)
      if (isDeviceWeb()) {
        gtag('event', EVENT_CARD_ACTIVATED, {
          send_to: googleAnalyticsSendToAccount,
        })
      }
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  LoanPaidOff(): void {
    try {
      if (AnalyticsDisabled()) return
      FBAppEventsLogger.logEvent(FbEventAchievementUnlocked)
      SingularSDK.logEvent(EVENT_UNLOCK_ACHIEVEMENT)
      void Firebase.logEvent(EVENT_UNLOCK_ACHIEVEMENT)
      Log.info(EVENT_UNLOCK_ACHIEVEMENT)
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  Login(): void {
    try {
      if (AnalyticsDisabled()) return
      FBAppEventsLogger.logEvent(FbEventLogin)
      SingularSDK.logEvent(SingularLogin)
      void Firebase.logEvent(GenericEventLogin)
      Log.info('LOGIN')
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  SessionStart(): void {
    try {
      if (AnalyticsDisabled()) return
      EventStreamSingleton.emit({
        type: 'analytic',
        name: AppEvents.Name.login_session_start,
        category: AppEvents.Category.Login,
      })
      SingularSDK.logEvent(GenericEventSessionStart)
      FBAppEventsLogger.logEvent(FbEventInitiatedCheckout) //TJC this seems wrong, shouldn't it be the same as the others.
      void Firebase.logEvent(GenericEventSessionStart)
      this._getBraze()?.logCustomEvent(GenericEventSessionStart)
      Log.info('SESSION_START')
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  TrackSingularAndFirebaseEvent(
    singularFirebaseEvent: string,
    params?: Record<string, string>,
  ): void {
    try {
      if (AnalyticsDisabled()) return
      SingularSDK.logEvent(singularFirebaseEvent, params)
      void Firebase.logEvent(singularFirebaseEvent, params)
    } catch (e) {
      Log.warn('Analytics, e : ', String(e))
    }
  }

  // eslint-disable-next-line max-params
  TrackAppEvent(
    eventName: AppEventName,
    _eventCategory: AppEventCategory,
    args?: AnalyticDataObject,
    logEventToSingular?: boolean,
  ): void {
    //this is throwing errors right now for webapp. disable till fix.
    if (AnalyticsDisabled()) return
    try {
      const shouldLogToBraze = brazeEvents[eventName] === true
      let brazeArgs = args
      if (typeof args === 'string') {
        brazeArgs = {value: args}
      }
      if (shouldLogToBraze) {
        this._getBraze()?.logCustomEvent(eventName, brazeArgs)
      }
      if (logEventToSingular) {
        SingularSDK.logEvent(eventName, args)
      }
      Log.info(JSON.stringify({eventName, brazeArgs}))
    } catch (e) {
      Log.log(`Analytics failed to track analytic, err : ${String(e)}`)
    }
  }
}

export default new Analytics()
