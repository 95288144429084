import React, {FC, useState} from 'react'

import {usePfDispatch} from 'src/store/utils'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {
  LoanCounterOfferLoanProps,
  LoanCounterOfferOnCompleteProps,
  LoanProcessingCounterOfferTemplate,
} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferTemplate'
import {
  CancelLoanDocument,
  LoanProcessingQueryDocument,
} from 'src/products/loans/LoanProcessing/LoanProcessingQuery.gqls'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  ApprovedLoanAggregateStatus,
  LoanAggregateStatus,
  UserProfile,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {
  getAdverseReasonDescription,
  isAmountOverOrEqualFiftyPercent,
} from 'src/products/loans/LoanProcessing/LoanProcessing.utils'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'

const isApprovedLoanAggregateStatus = (
  loanAggregateStatus?: Partial<LoanAggregateStatus>,
): loanAggregateStatus is ApprovedLoanAggregateStatus => {
  return (
    loanAggregateStatus !== undefined &&
    loanAggregateStatus.__typename === 'ApprovedLoanAggregateStatus'
  )
}

type Props = Partial<LoanCounterOfferLoanProps> & LoanCounterOfferOnCompleteProps

export const LoanProcessingCounterOfferGQLContainer: FC<Props> = (props) => {
  const {onContinue, onCancel} = props
  const dispatch = usePfDispatch()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_counter_offer_screen_viewed,
    eventCategory: AppEvents.Category.Activation,
  })

  const {
    selectedData: me,
    loading: isLoading,
    error,
  } = useCassandraQuery(
    LoanProcessingQueryDocument,
    {fetchPolicy: 'cache-first'},
    (data) => data.me,
  )
  const profile: Partial<UserProfile> | undefined | null = me?.profile
  const firstName = profile?.name?.firstName
  let reason = ''
  let amountBorrowed = ''
  let amountRequested = ''
  let isAmountApprovedOverOrEqualFiftyPercent = false

  const loanAggregateStatus = me?.loans.latestActionableLoan?.aggregateStatus
  if (isApprovedLoanAggregateStatus(loanAggregateStatus)) {
    const counterOfferCode = loanAggregateStatus.counterOfferCodes?.codes?.[0] ?? undefined
    reason = getAdverseReasonDescription(counterOfferCode)
    amountBorrowed = loanAggregateStatus.amountApproved
    amountRequested = loanAggregateStatus.amountRequested
    isAmountApprovedOverOrEqualFiftyPercent = isAmountOverOrEqualFiftyPercent(
      parseFloat(amountBorrowed),
      parseFloat(amountRequested),
    )
  }

  const [cancelLoan] = useCassandraMutation(CancelLoanDocument)
  const [isContinueLoading, setIsContinueLoading] = useState(false)

  const handleOnContinue = async (): Promise<void> => {
    setIsContinueLoading(true)
    TrackAppEvent(AppEvents.Name.loan_counter_offer_accepted, AppEvents.Category.Activation)
    await dispatch(UserStateRefresh())
    await onContinue()
    setIsContinueLoading(false)
  }
  const handleOnCancel = async (): Promise<void> => {
    try {
      const {errors} = await cancelLoan({
        variables: {
          loanId: me?.loans.latestActionableLoan?.id ?? '',
          reason: 'Counter offer declined',
          reasonCodes: [],
        },
      })
      if (errors && errors.length > 0) {
        throw errors[0]
      }
      await onCancel()
    } catch (error) {
      logOfferActivationErrorAndShowException(
        error,
        'LoanProcessingCounterOfferGQL failed to cancel loan',
      )
    }
  }

  return (
    <BaseTemplate isLoading={isLoading && !me} isError={!!error}>
      <LoanProcessingCounterOfferTemplate
        reason={reason}
        firstName={firstName}
        isAmountApprovedOverOrEqualFiftyPercent={isAmountApprovedOverOrEqualFiftyPercent}
        amountBorrowed={amountBorrowed}
        amountRequested={amountRequested}
        onContinue={handleOnContinue}
        onCancel={handleOnCancel}
        isOnContinueLoading={isContinueLoading}
      />
    </BaseTemplate>
  )
}
