import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'
import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import Money from 'src/assets/illustrations/Money.svg'
import {formatDate, monthDayShort} from 'src/lib/utils/date'
import {LoanTypeVariant} from 'src/cassandra'
import {isAdvanceVariant} from 'src/lib/advance/CashAdvanceUtils'

export type LoanApprovedTileProps = {
  amount: number
  expirationDate: Date
  onAcceptOffer: ButtonAction
  testID?: string
  loanTypeVariant?: LoanTypeVariant
}

/**
 * A tile to inform the user that their loan has been approved.
 * Includes an action button to accept the offer.
 * @example <LoanApprovedTile amount={250} onAcceptOffer={() => foo()} />
 */
export const LoanApprovedTile: React.FC<LoanApprovedTileProps> = ({
  amount,
  expirationDate,
  onAcceptOffer: handleOnAcceptOffer,
  testID,
  loanTypeVariant,
}: LoanApprovedTileProps) => {
  const expirationDateFormatted = formatDate(expirationDate, monthDayShort)
  const {t} = useTranslation('DashboardLoanApprovedTile')
  const isAdvance = isAdvanceVariant(loanTypeVariant)
  return (
    <BasicTile padding="medium" align="center" testID={testID}>
      <Box marginTop="medium" marginBottom="large" width={'100%'} align="center">
        <PFSvgContain svg={Money} width={'100%'} maxWidth={170} />
      </Box>
      <Box width="auto" gap="medium">
        <PFText variant="h3">
          {isAdvance
            ? t('YoureApprovedForAnAdvance', {amount})
            : t('YoureApprovedForALoan', {amount})}
        </PFText>
        <Box>
          <PFText variant="p">{t('AcceptDetails', {expirationDateFormatted})}</PFText>
        </Box>
        <Box align="center">
          <Button
            mode="primary"
            size="large"
            onPress={handleOnAcceptOffer}
            width="100%"
            testID="AcceptOfferBtn"
          >
            {isAdvance ? t('ContinueOffer') : t('AcceptOffer')}
          </Button>
        </Box>
      </Box>
    </BasicTile>
  )
}
